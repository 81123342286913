import { Dropdown, Menu } from 'antd';
import './style.css';

const UIDropdown = (props:any) => {

  const menu = (
    <Menu
      items={props.items}
    />
  );

return (
  <Dropdown placement={props.placement} overlay={menu} trigger={['click']}>
 
     {props.children}

  </Dropdown>
)
};

export { UIDropdown }; 