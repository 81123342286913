/* eslint react-hooks/exhaustive-deps: 0 */

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authStateSelector } from './redux/features/auth/authSlice';
import UIRoutes from './routes';
import { socket } from './redux/services/webSocket';


function App() {
  const {user, tenantId} = useSelector(authStateSelector)
  console.log("secret key:", process.env.REACT_APP_LOCAL_STORAGE_SECRET)

  //For Connecting Websocket
  useEffect(()=>{
    if (user?.userId) {
      socket.auth = { userId: user?.userId, tenantId: tenantId }
      socket.connect()
    }
  },[user])
  return (
     <UIRoutes />
  );
}

export default App;
