import { apiSlice } from "./apiSlice";
import { apiRoutes } from "../../config/api";
import { Roles } from "../../config/enum";

export const siteService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSite: builder.query({
      query: (params?: any) => {
        const { userId,roleName, queryParams } = params;
        if (roleName===Roles.PLATFORM_ADMIN || roleName===Roles.PLATFORM_SUPER_ADMIN) {
          return {
            url: `${apiRoutes.GETALLSITES}`,
            method: "GET",
          };
        } else {
          return {
            url: `${apiRoutes.GETALLSITEBYUSER}/${userId}`,
            params: queryParams,
            method: "GET",
          };
        }
      },
    }),
  
    getAllDeviceBySite: builder.query({
      query: (params: any) => {
        const { siteId } = params;
        if (siteId) {
          return {
            url: `${apiRoutes.GETALLDEVICEBYSITE}/${siteId}`,
            method: "GET",
          };
        }
      },
    }),

    processExecute: builder.mutation({
      query: (formData: any) => {
          return {
            url: `${apiRoutes.PLAY_EXECUTEPROCESS}`,
            method: "POST",
            body: formData,
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          };
      },
    }),

    cancelExecuteProcess: builder.mutation({
      query: (formData: any) => {
          return {
            url: `${apiRoutes.CANCEL_SITEBUTTON}`,
            method: "POST",
            body: formData,
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          };
      },
    }),

    createProcessState: builder.mutation({
      query: (formData: any) => {
          return {
            url: `${apiRoutes.CREATE_STATE}`,
            method: "POST",
            body: formData,
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          };
      },
    }),

    zoneySite : builder.query({
      query: (params: any) => {
        const { siteId } = params;
        if (siteId) {
          return {
            url: `${apiRoutes.ZONE_BY_SITE}/${siteId}`,
            method: "GET",
          };
        }
      },
    }),
  }),
});

export const {
  useGetAllSiteQuery,
  useGetAllDeviceBySiteQuery,
  useProcessExecuteMutation,
  useCancelExecuteProcessMutation,
  useCreateProcessStateMutation,
  useZoneySiteQuery
} = siteService;
