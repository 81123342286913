import { Modal, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { hourglass } from "../../../assets/images";
import './style.css'
import { ArrowLeftOutlined } from "@ant-design/icons";

export const ProcessPausedModal = (props: any) => {
  const { openAlert, setOpenAlert, errorDetail, setErrorDetail } = props;

  const navigate = useNavigate();

  return (
    <Modal
      visible={openAlert}
      footer={null}
      title={null}
      closable={false}
      key="alertmodal"
      className="inclass"
    >
      <Row justify="center">
      <Col span={5}>
                        <img src={hourglass} width="129px" height="90px" />
                      </Col>
        <Col span={24} className="alertText errorAlert">
         Process will be paused automatically after completion of the running cycle!
        </Col>
        <Col span={10} className="goback" onClick={()=>{
          navigate('/')
        }}>
        <ArrowLeftOutlined /><u>  Go to Active Process</u>
          </Col>
      </Row>
    </Modal>
  );
};
