/* eslint react-hooks/exhaustive-deps: 0 */

import { LeftOutlined, ReloadOutlined, SyncOutlined } from "@ant-design/icons";
import { Alert, Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { syncOff, onlineLog, offlineLog, syncOn } from "../../../assets/images";
import { PageTitle, WebsocketKey } from "../../../config/enum";
import { setIsPaused, setPausedData, setSyncedData } from "../../../redux/features/app/appSlice";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { useProcessTagDataQuery } from "../../../redux/services/tagSlice";
import { socket } from "../../../redux/services/webSocket";
import { UIImage } from "../../common/image";
import { ActivityLogsTable } from "./activitylogsTable";
import { DeviceDetail } from "./deviceDetail";
import './style.css'
import { ProcessName } from "../../../config/constants";
import { Page } from "../../../routes/config";
import { ErrorModal } from "../../common/errorModal";
import { ProcessPausedModal } from "../../common/pausedModal";

export const ActivityLogs = () => {
  const dispatch = useDispatch()
  const {state}:any = useLocation()
  
  const {isPausedCompleted, pausedData} = useSelector((state:any)=>state.app)
  const [syncedData, setSyncedData] = useState<any>()
  const {user, tenantId} = useSelector(authStateSelector)
  const [errorModalVisible, setErrorModalVisible] = useState(false)
  const [showAlert, setShowAlert] = useState(false) 
  const [notifyAlert,setNotifyAlert] = useState<any>()

  const data:any = state ? state : undefined
  const tagsProcessData = useProcessTagDataQuery({ processId: data?.data?.processId, deviceId: data?.data?.deviceId, zoneId:data?.data?.zoneId, siteId: data?.data?.siteId })

  // useEffect(()=>{
  //   dispatch(setSideBarState(false))
  //   // return(()=>{dispatch(setSideBarState(true))
  //   // dispatch(setTableData([]))
  //   setSyncedData({})}
  //   )},[])


  const navigate = useNavigate()
  
  // socket.on("connect",()=>{
  //   setDisconnect("Online")
  // })

console.log(">>>> error", `${WebsocketKey.LastSync}-${data?.data?.deviceId}-${data?.data?.processId}`)
  socket.on(`${WebsocketKey.LastSync}-${data?.data?.deviceId}-${data?.data?.processId}`,(lastSyncdata:any)=>{
    console.log("sync data",lastSyncdata)
    if((data?.data?.processId == lastSyncdata?.data?.processId) && (data?.data?.deviceId == lastSyncdata?.data?.deviceId )){
      setSyncedData(lastSyncdata?.data)
    }
  })

 useEffect(()=>{
  if(tagsProcessData?.data){
    setSyncedData({...tagsProcessData?.data?.result})
  }
 },[tagsProcessData?.data, tagsProcessData?.isSuccess])

 const getCaptureContent = () => {
  switch (data?.data?.processName) {
    case ProcessName.CHECK_STOCK:
      return (<>
        <Col span={24} className="capture">Scan cycles since start : {syncedData?.totalCycles ? syncedData?.totalCycles : 0 }</Col>
        <Col span={24} className="capture">Unsuccessful scans<span className="marginLeft"> : {syncedData?.totalFailed ? syncedData?.totalFailed : 0}</span>
        </Col>
        </>
      )
    default:
      return (<>
        <Col span={24} className="capture">Items captured since login : {syncedData?.totalCaptured ? syncedData?.totalCaptured : 0 }</Col>
        <Col span={24} className="capture">Items synced to portal<span className="marginLeft"> : {syncedData?.totalSynced ? syncedData?.totalSynced : 0}</span>
        </Col>
        </>
      )
  }
 }

 const handleClose = () => {
   setShowAlert(false)
 }

 console.log(state.data, "state>>>>>>>>>>>>>>>>")
 socket.on(
   `${WebsocketKey.Notification_error}-${state?.data?.deviceId}-${state?.data?.processId}`,
   (data: any) => {
     if (
       window.location.pathname === Page.ACTIVITY_LOGS &&
       state?.data?.processId === data?.processId &&
       state?.data?.deviceId === data?.deviceId
     ) {
       setShowAlert(true);
       setNotifyAlert(data?.data);
     }
     console.log(data, "message for alert event");
   }
 );

 socket.on(
  `${WebsocketKey.ReaderIssues}-${state?.data?.deviceId}-${state?.data?.processId}`,
  (data: any) => {
 console.log(`${WebsocketKey.ReaderIssues}-${state?.data?.deviceId}-${state?.data?.processId}`, "device issues")

    if (
      window.location.pathname === Page.ACTIVITY_LOGS &&
      state?.data?.processId === data?.processId &&
      state?.data?.deviceId === data?.deviceId
    ) {
      setShowAlert(true);
      setNotifyAlert(data?.data);
    }
    console.log(data, "message for alert event");
  }
);



console.log(showAlert, "show alert state")

const refresh = () =>{
  window.location.reload()
}

  return (
    <>
      {showAlert && (
        <div className="alert-overlay">
          <Alert
            description={
              notifyAlert?.error?.message
                ? notifyAlert?.error?.message
                : notifyAlert?.message
            }
            type="info"
            className="alertColor"
            showIcon
            closable
            style={{ marginTop: 16 }}
            onClose={handleClose}
          />
        </div>
      )}
      <Row gutter={24} className="cardFirst">
        <Col md={11} lg={13} xl={13}>
          {/* <section className="listing-section"> */}
          <Card className="uicard table activityLogsTable">
            <Row className="justifyText">
              <Col span={4} style={{ marginBottom: "5px" }}>
                <div onClick={() => navigate(-1)} className="backButton">
                  <LeftOutlined className="bckIcn left-back-button" />
                  <b className="top-back-text">BACK</b>
                </div>
              </Col>
              <Col>
              <p className="refresh" onClick={refresh}><SyncOutlined className="reloadButton"/>  Refresh</p>
              </Col>
              <Col span={20}></Col>
              {/* <Col span={4} style={{ marginBottom: "5px" }} className="reload">
                  <UIbutton type="info" size="sm" >REFRESH <ReloadOutlined/> </UIbutton>
              </Col> */}
              <Col sm={24} md={24} lg={12} xl={14} className="title flex">
                {PageTitle.ACTIVITY_LOGS}
              </Col>
              <Col sm={24} md={24} lg={12} xl={10} className="columnContent">
                <Row className="itemCapture">{getCaptureContent()}</Row>
              </Col>
            </Row>
            <hr className="hr" />
            {/* <b className="heading-rs">Reference Stock Set for Zone: <span className="zoneNameCSS">{data?.data?.zoneName}</span></b> */}
            <ActivityLogsTable
              logsTableData={data}
              tagsReadData={tagsProcessData}
              processData={data?.data}
            />
          </Card>
          {/* </section> */}
        </Col>
        <Col md={13} lg={11} xl={11}>
          {/* <section className="listing-section"> */}
          <Card className="uicard activityLogsTable">
            <DeviceDetail data={data} syncedData={syncedData} setSyncedData={setSyncedData} />
          </Card>
          {/* </section> */}
        </Col>
      </Row>
      {/* </div> */}

      {/* <Row className="cardBottom">
        <Card className="uicard card-container ">
          <Row>
            <Col span={24} className="lastLogsCard">
              {data?.data?.processName !== "Check Reference Stock" ? (
                <></>
                // <>
                //   {" "}
                //   <UIImage
                //     src={
                //       syncedData?.totalSynced || syncedData?.totalCycles
                //         ? syncOn
                //         : syncOff
                //     }
                //     width="60px"
                //     height="60px"
                //   />
                //   <span
                //     className={
                //       syncedData?.totalSynced || syncedData?.totalCycles
                //         ? "online synced"
                //         : "off-sync synced"
                //     }
                //   >
                //     <b>
                //       {syncedData?.totalSynced || syncedData?.totalCycles
                //         ? "Synced"
                //         : "Not Synced"}
                //     </b>
                //   </span>
                // </>
              ) : (
                <></>
              )}
              {/* <UIImage
                src={disconnect === "Online" ? onlineLog : offlineLog}
                width="60px"
                height="60px"
              />
              <span
                className={
                  disconnect === "Online" ? "online synced" : "offline synced"
                }
              >
                <b>{disconnect}</b>
              </span> */}
            {/* </Col>
          </Row>
        </Card>
      </Row> */}
    </>
  );
};
