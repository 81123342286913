import { Card, Col, Row } from 'antd';
import { UIImage } from '../../common/image';
import { officeDevice, offlineDevice } from '../../../assets/images';
import './style.css'

export const DeviceCard = (props: any) => {

    const { device, statusdevice } = props
    const Obj: any = statusdevice?.find((status: any) => status?.deviceMac === device?.mac)
    const deviceStatusChange = () => {
        if (device?.status === 'Active-Idle' || device?.status === 'Active-Running') {
            if (Obj?.status === 'connected' || Obj?.status === 'running') {
                return (
                    <><Col span={24} className="card-img" data-testid="imagee-id">
                        <UIImage src={officeDevice} alt="officedevice" height="85px" width="85px" />
                    </Col>
                        <Col span={24} className='onlineDevice'><b>Online</b></Col></>)
            } else {
                return (
                    <><Col span={24} className="card-img" data-testid="imagee-id">
                        <UIImage src={offlineDevice} alt="offlineDevice" height="85px" width="85px" />
                    </Col>
                        <Col span={24} className='offlineDevice'><b>Offline</b></Col></>)
            }
        } else if (device?.status === 'Inactive') {
            return (
                <><Col span={24} className="card-img" data-testid="imagee-id">
                    <UIImage src={offlineDevice} alt="offlineDevice" height="85px" width="85px" />
                </Col>
                    <Col span={24} className='offlineDevice'><b>Offline</b></Col></>)
        }
    }


    return (
        <Card className='card-content' data-testid="cardd-id" >
            <Row className='card-box'>
                <Col span={24} className='card-heading deviceDetail slice' data-testid="heading-id">{device?.name}</Col>
                <Col xl={24} lg={20} md={15} sm={10} className='deviceDetail slice'>{device?.tenantName} | {device?.deviceSiteZoneProcess[0]?.siteName} | {device?.deviceSiteZoneProcess[0]?.zoneName}</Col>
            </Row>
            <Row className='card-box'>
                {deviceStatusChange()}
            </Row>
        </Card>
    )
}