import socketIOClient,{ Manager } from 'socket.io-client';
import { baseURL } from '../../config/api';

export const _manager = new Manager(baseURL,{
    // reconnectionDelayMax: 10000,
    autoConnect: true
    
});

export const _io = socketIOClient;
export const _url = (baseURL);
export const socket = _io(_url, {
       autoConnect: false,
     })