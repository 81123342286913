import { WebsocketKey } from "../../../config/enum";
import { UITable } from "../../common/table";
import { socket } from "../../../redux/services/webSocket";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectCurrentAppState } from "../../../redux/features/app/appSlice";
import { useEffect, useState } from "react";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { ProcessName } from "../../../config/constants";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Descriptions } from "antd";
import UITooltip from "../../common/tooltip";

export const ActivityLogsTable = (props: any) => {
  const { logsTableData, tagsReadData, processData } = props;
  const { state }: any = useLocation();

  const [stateData, setStateData] = useState(state);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<any>([]);
  const { stopbutton } = useSelector(selectCurrentAppState);

  const dataOfTags = (data: any[]) => {
    let tagsData: any[] = [];
    data?.forEach((el: any) => {
      let x = tagsData?.findIndex((y: any) => y?.upc == el?.upc);
      if (x != -1) {
        tagsData[x] = { ...tagsData[x], qty: tagsData[x]?.qty + 1 };
      } else {
        tagsData.push({ ...el, qty: 1 });
      }
    });
    return tagsData;
  };

  useEffect(() => {
    setStateData(state);
  }, [state]);

  useEffect(() => {
    console.log(tagsReadData, ">>>>>>>>>>>>>>>>...tags data")

    if (tagsReadData?.data?.result) {
      console.log(tagsReadData, ">>>>>>>>>>>>>>>>...tags data2")

      if (processData.processName == ProcessName.CHECK_STOCK) {
        console.log(tagsReadData, ">>>>>>>>>>>>>>>>...tags data")
        setTableData(tagsReadData?.data?.result?.data);
      } else {
        setTableData(dataOfTags(tagsReadData?.data?.result?.data));
      }
    }
  }, [tagsReadData?.data, tagsReadData?.isSuccess]);

  let i = 1;

  // socket.on(`${WebsocketKey.ACTIVITY_LOGS_TABLE}-${stateData?.data?.deviceId}-${stateData?.data?.processId}`, (data: any) => {
  //   console.log(data, "data from activity log");
  //   let existingdata = tableData;
  //   if (processData.processName == ProcessName.CHECK_STOCK) {
  //     if (data?.state !== "TrackNTrace") {
  //       console.log(data, "data .......", logsTableData);
  //       if (
  //         stateData?.data?.processId == data?.data?.processId &&
  //         stateData?.data?.deviceId == data?.data?.deviceId
  //       ) {
  //         // console.log(`process- ${data?.data?.processId}:ws and ${logsTableData?.data?.processId}:state|| device- ${data?.data?.deviceId}:ws and ${logsTableData?.data?.deviceId}:state`)
  //         console.log(
  //           "setting data",
  //           data?.data?.deviceId,
  //           stateData?.data?.deviceId,
  //           i
  //         );
  //         setTableData([{ ...data?.data }, ...existingdata]);
  //       }
  //     }
  //   } else {
  //     if (data?.data?.data) {
  //       if (
  //         stateData?.data?.processId === data?.data?.processId &&
  //         stateData?.data?.deviceId === data?.data?.deviceId
  //       ) {
  //         setTableData([
  //           ...tableData,
  //           { ...data?.data?.data?.data, qty: 1 },
  //         ]);
  //         const x = tableData?.find(
  //           (y: any) => y?.upc === data?.data?.data?.data?.upc
  //         );
  //         if (x) {
  //           setTableData(
  //             tableData?.map((y: any) => {
  //               if (x.upc === y.upc) {
  //                 return { ...y, qty: y.qty + 1 };
  //               } else {
  //                 return y;
  //               }
  //             })
  //           );
  //         }
  //       }
  //     } else if (!data?.data?.data) {
  //       return null;
  //     }
  //   }
  // });

  useEffect(() => {
    if (stopbutton === "") {
      setTableData([]);
    } else setTableData([...tableData]);
  }, []);

  useEffect(() => {
    console.log("CHANGES", logsTableData);
  }, [logsTableData]);

  const getTimezone = (value:any) => {
    const date = new Date(value); // Use a dummy date to set time
    const options:any = { hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };

    let formattedDate = date.toLocaleString('en-US', {timeZoneName: "shortGeneric",});
    const [datePart, timePart] = formattedDate.split(', ');

    // Format the date part as MM-DD-YYYY
    const [month, day, year] = datePart.split('/');
    const formattedDateString = `${month}-${day}-${year}`;

    // Combine the formatted date with the time and timezone abbreviation
    return `${formattedDateString} ${timePart}`;
  };

  const getTableColumns = (processName: any) => {
    let renderColumns: any[] = [];

    switch (processName) {
      case ProcessName.CHECK_STOCK:
        renderColumns = [
          {
            title: "TagId",
            dataIndex: "tagId",
            className: "noPadCell",
            width: "20%",
            render: (x: any, row: any) => {
              return (
                <div>
                  <span className="tableData">{x}</span>
                </div>
              );
            },
          },
          {
            title: "EPC",
            dataIndex: "epc",
            className: "centerTable",
            width: "20%",
            render: (x: any, row: any) => {
              return (
                <div>
                  <span className="tableData">{x}</span>
                </div>
              );
            },
          },
          {
            title: "Description",
            dataIndex: "productDescription",
            className:"activeProcessTable",
            width: "15%",
            ellipsis: true,
            render: (description: any, row: any) => {
              return (
                <div className="d-flex align-items-center">
                  <UITooltip title={row?.tenantName} placement="top">
                    <div className="pl-10 tableData">{description}</div>
                  </UITooltip>
                </div>
              );
            },
          },
          {
            title: "Last Updated",
            dataIndex: "createdAt",
            width: "15%",
            className: "centerTable",
            render: (field: any, row: any) => {
              return (
                <div
                  className="d-flex align-items-center"
                >
                  <div className="pl-10 tableData">{getTimezone(moment(row?.createdAt).format("YYYY-MM-DD hh:mm:ss A"))}</div>
                </div>
              );
            },
          },
        ];

        break;
      default:
        renderColumns = [
          {
            title: "UPC",
            dataIndex: "upc",
            render: (field: any, row: any) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="pl-10 tableData">{field}</div>
                </div>
              );
            },
          },
          {
            title: "PRODUCT NAME",
            dataIndex: "description",
            render: (_: any, row: any) => {
              return (
                <div>
                  <span className="tableData">{row?.description}</span>
                </div>
              );
            },
          },
          {
            title: "QTY",
            dataIndex: "qty",
            render: (createdAt: any, row: any) => {
              return <div>{row?.qty}</div>;
            },
          },
        ];
    }
    return renderColumns;
  };
  const columns = [
    {
      title: "UPC",
      dataIndex: "upc",
      render: (field: any, row: any) => {
        return (
          <div className="d-flex align-items-center">
            <div className="pl-10 tableData">{field}</div>
          </div>
        );
      },
    },
    {
      title: "PRODUCT NAME",
      dataIndex: "description",
      render: (_: any, row: any) => {
        return (
          <div>
            <span className="tableData">{row?.description}</span>
          </div>
        );
      },
    },
    {
      title: "QTY",
      dataIndex: "qty",
      render: (createdAt: any, row: any) => {
        return <div>{row?.qty}</div>;
      },
    },
  ];

  return (
    <>
      <div className="card-body activityTable1">
        <UITable
          columns={getTableColumns(processData?.processName)}
          data={tableData}
          pagination={false}
          scroll={{ x: 800, y: 500 }}
          className="activitytable"
        />
      </div>
    </>
  );
};
