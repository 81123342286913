/* eslint react-hooks/exhaustive-deps: 0 */

import { Card, Col, Row, Empty } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { useGetAllDeviceOfUserQuery, useGetDeviceStatusQuery } from "../../../redux/services/deviceSite";
import { CompWrapper } from "../../common/customScrolbar";
import { DeviceCard } from "./deviceCard";
import "./style.css";

export const DeviceTest = (props: any) => {

  const {user} = useSelector(authStateSelector)
  
  const deviceDataQuery = useGetAllDeviceOfUserQuery({userId:user?.userId},{refetchOnMountOrArgChange:true})
  const getDeviceStatus = useGetDeviceStatusQuery({userId:user?.userId},{refetchOnMountOrArgChange:true, pollingInterval:3000})

  useEffect(()=>{
    deviceDataQuery?.refetch()
    getDeviceStatus?.refetch()
  },[getDeviceStatus?.isSuccess,deviceDataQuery?.isSuccess])

  return (
    <>
      <section className="listing-section">
        <Card className="uicard table card-body-device uiinner-card">
          <CompWrapper>
            {deviceDataQuery?.data?.result?.deviceData <= 0 ?
              <div className="noDataFound">
                <Row>
                  <Col span={24}>
                    <Empty />
                  </Col>
                </Row>
              </div>
              :
              <Row gutter={[15, 15]}>
                {deviceDataQuery?.data?.length !== 0
                  ? deviceDataQuery?.data?.result?.deviceData?.map((obj: any,id: any) => (
                    <Col span={8} key={id}>
                      <DeviceCard device={obj} statusdevice={getDeviceStatus?.data?.result} tenantData={deviceDataQuery?.data?.result} />
                    </Col>
                  ))
                  : null}
              </Row>}
          </CompWrapper>
        </Card>
      </section>
    </>
  );
};
