export const removeEmptyKeys = (obj:any) => {
    let filtered:any = {};
    Object.keys(obj).forEach(function (key) {
      if (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined  ) {
        filtered[key] = obj[key];
      }
    });
    return filtered;
  }
  
  export const getHeight = (classname : string) => {
    const node = document.querySelector<any>(classname);
    if( node !== null|| node !== undefined)
    {
      const screenHeight = document.body?.clientHeight;
      const remainingHeight = screenHeight-node?.getBoundingClientRect().y;
      return remainingHeight
    }
    return 0;
  }