import { apiSlice } from "./apiSlice"
import { apiRoutes } from "../../config/api"

export const processService = apiSlice.injectEndpoints({
    endpoints: builder => ({
          auditlogs : builder.query({
            query: (queryParams:any) => {
              return {
                url: `${apiRoutes.AUDITS}`,
                method: "GET",
                params: queryParams
              };
            },
          }),

          prevAuditlogs : builder.query({
            query: (queryParams:any) => {
              let {siteId, zoneId} = queryParams
              if(siteId && zoneId){
                return {
                  url: `${apiRoutes.AUDITS}`,
                  method: "GET",
                  params: queryParams
                };
              }
            },
          }),
    })
})

export const {
            useAuditlogsQuery,
            usePrevAuditlogsQuery 
} = processService