import { createSlice } from '@reduxjs/toolkit'

interface TenantState {
  id: string
}

const initialState = { id:  ""} as TenantState

const slice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setTenant(state, action) {
      state.id = action.payload
    },
    resetTenant(state, action) {
      state = initialState
    }
  },
})

export const { setTenant, resetTenant } = slice.actions
export default slice.reducer