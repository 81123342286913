import { Col, Modal, Row, Space } from "antd";
import { inviteIcon } from "../../../assets/icons";
import { redCross, greenTick } from "../../../assets/images";
import { ModalType } from "../../../config/enum";
import { UIbutton, UIsecondaryButton } from "../button";
import "./style.css"

export const UIConfirmModal = (props: any) => {
  const {
    type,
    primaryText,
    visible,
    confirmButton,
    cancelButton,
    confirmCallback,
    cancelCallback,
    className,
    cancelSiteButton,
    btnType,
    form
  } = props;  

  const getIconType:any = () => {
    if (type === ModalType.WARN) return redCross;
    else if (type === ModalType.INVITE) return inviteIcon;
    else if (type === false) return null;
    else return greenTick;
  };

  const altText: any = () => {
    if (type === ModalType.WARN) return "redCross";
    else if (type === ModalType.INVITE) return "inviteIcon";
    else if (type === false) return "";
    else return "greenTick";
  }

  return (
    <Modal
      wrapClassName={className ? `${className} modalClass` : "modalClass"}
      visible={visible}
      onCancel={cancelCallback}
      centered
      footer={null}
      closable={false}
    >
      {props.children}
      <Row justify="center">
        <Col>
          <img className="redCoss" src={getIconType()} alt={altText()} />
        </Col>
      </Row>
      <Row justify="center">
        <Col xl={24}>
          <div className="primaryText">
            <span>{primaryText}</span>
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Space size={20}>
          <Col span={24}>
          <UIsecondaryButton onPress={cancelSiteButton ? cancelSiteButton : cancelCallback}>
            {cancelButton}
          </UIsecondaryButton>
          </Col>
          <Col span={24}>
          <UIbutton type="info" btnType={btnType ? btnType : null} form={form} onPress={confirmCallback}>
            {confirmButton}
          </UIbutton>
          </Col>
        </Space>
      </Row>
    </Modal>
  );
};
