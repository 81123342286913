/* eslint react-hooks/exhaustive-deps: 0 */

import {useState, useEffect} from 'react';
import { getHeight } from '../../../utility/utils'
import "./style.css"

export const CompWrapper = (props: any) => {
    const [ contentHeight, setContentHeight ] = useState(0);
    const name = props.name ?? 'scrollContent';

    useEffect(()=>{
    const resizeObserver = new ResizeObserver((entries) => {
        setContentHeight(getHeight(`.${name}`))
      });
      resizeObserver.observe(document.body);
      props?.observeOn && resizeObserver.observe(document.querySelector<any>(`.${props?.observeOn}`));
      setContentHeight(getHeight(`.${name}`))
      return (()=>{setContentHeight(0)})
    }, []);

    return (
        <div className={`scrollContent scrollStyle ${props?.otherClasses ?? ''}`} style={{height:contentHeight - 100 }}>
            {props.children}    
        </div>
    );
}
