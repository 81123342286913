import { apiSlice } from "./apiSlice"
import { apiRoutes } from "../../config/api"

export const processService = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllDeviceOfUser: builder.query({
            query: (params: any) => {
                const {userId} = params;
              return {
              url: `${apiRoutes.USER_DEVICE}/${userId}`,
              params: params,
              method: "GET",
            }},
          }),

        getDeviceStatus:builder.query({
          query: (params:any) => {
            const {userId} = params;
            return {
              url: `${apiRoutes.DEVICE_STATUS}/${userId}`,
              params: params,
              method:'GET',
            }
          }
        }),

        getDeviceStatusByMac:builder.query({
          query:(params:any) => {
            const {macDevice} = params;
            return {
              url: `${apiRoutes. DEVICE_STATUS_BY_MAC}/${macDevice}`,
              params: params,
              method: "GET"
            }
          }
        })
    })
})

export const {
            useGetAllDeviceOfUserQuery,
            useGetDeviceStatusQuery,
            useGetDeviceStatusByMacQuery 
} = processService