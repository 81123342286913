import { createSlice } from "@reduxjs/toolkit"
const initialState = { user: null, token:null, tenantId: null, isLoggedIn: null } as {
    user: null | any;
   token: null |any;
   tenantId: null | any;
    isLoggedIn: null | boolean;
}
const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserData(
            state,
            { payload }
        ) {
                 state.user = payload?.userInfo;
                 state.token = payload?.token;
                 state.tenantId = payload?.tenantId;
                 state.isLoggedIn = payload?.isLoggedIn;
               
        },
        logOut(state,_) {
            state = { user: null, token:null, tenantId: null, isLoggedIn: null}
            return state
        }
    },
    extraReducers: (builder) => { }
});

export const { setUserData, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: any) => state.auth.user
export const authStateSelector = (state:any) => state.auth
export const isLoggedIn = (state: any) => state.auth.isLoggedIn