import { Table } from 'antd';
import './style.css';
const UITable = ({columns, data, pagination, rowKey, loading, scroll, handleTableChange, className, checkbox=false}:any) => {
 
  let renderData: any[]

  renderData = data?.map((item:any)=>{return {...item, key: data?.id}})
  return (
    <Table
    // bordered
    rowSelection={checkbox}
    columns={columns}
    dataSource={renderData}
    pagination={pagination}
    loading={loading}
    onChange={handleTableChange}
    className={className?`uitable ${className}`:"uitable"}
    scroll={scroll}
  />
    

  );
};

export  { UITable };