/* eslint react-hooks/exhaustive-deps: 0 */

import { Card, Col, message, Row, TablePaginationConfig } from "antd";
import { useEffect, useState } from "react";
import { deleteDarkIcon } from "../../../assets/icons";
import { ModalButton, ModalPrimaryText, ModalType } from "../../../config/enum";
import { UIConfirmModal } from "../../common/confirmModal";
import { UIDropdown } from "../../common/dropdown";
import * as util from "util";
import { UITable } from "../../common/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { page, rolesOrder } from "../../../config/constants";
import {
  removeCurrentStep,
  removeProcessState,
  setIsPaused,
  setProcessState,
  setSyncedData,
} from "../../../redux/features/app/appSlice";
import {
  useGetAllActiveProcessQuery,
  useGetAllPreviousPocessQuery,
  useStopActiveProcessMutation,
} from "../../../redux/services/processSlice";
import { useDispatch, useSelector } from "react-redux";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import UITooltip from "../../common/tooltip";
import { AuditOutlined, EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  getFilterInfo,
  setFilterState,
} from "../../../redux/features/filter/filterSlice";
import { UIAvatar } from "../../common/avatar";
import { UICalendar } from "../../common/uiCalendar";
import {
  useAuditlogsQuery,
  usePrevAuditlogsQuery,
} from "../../../redux/services/auditSlice";
import { UISelectInput } from "../../common/selectInput";
import {
  useGetAllSiteQuery,
  useZoneySiteQuery,
} from "../../../redux/services/siteSlice";

export const PreviousProcess = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const filterState = useSelector(getFilterInfo);
  const currentURL = window.location.href;
  const [dateParams, setDateParams] = useState();
  const [queryparams, setQueryparams] = useState<any>({});
  const [date, setDate] = useState<any>()
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current:
      filterState?.page && currentURL == filterState?.url
        ? filterState?.page
        : page?.current,
    pageSize:
      filterState.limit && currentURL == filterState?.url
        ? filterState.limit
        : 50,
    showSizeChanger: true,
    total: 0
  });
  const { tenantId, user } = useSelector(authStateSelector);
  const [sort, setSort] = useState<any>({
    ...(currentURL == filterState?.url &&
      filterState?.sortBy && { sortBy: filterState?.sortBy }),
    ...(currentURL == filterState?.url &&
      filterState?.sortOrder && { sortOrder: filterState?.sortOrder }),
  });

  const navigate = useNavigate();
  interface RowData {
    timestamp?: string;
    eventName?: string;
    userName?: string;
    message?: string;
    detail?: string[];
    deviceName?: string;
    deviceStatus?: string;
    [key: string]: any;
  }

  const auditParams: any = {
    siteId: queryparams.siteId,
    zoneId: queryparams.zoneId,
    page: pagination.current || 1,
    limit: pagination.pageSize || 10,
    to: date?.to,
    from: date?.from,
    ...sort,
  };

  const getAllSiteByUserQuery = useGetAllSiteQuery(
    {
      tenantId:
        user?.roleName !== "Platform Super Admin" &&
        user?.roleName !== "Platform Admin"
          ? tenantId
          : null,
      userId: user?.userId,
      roleName: user?.roleName,
    },
    { refetchOnMountOrArgChange: true }
  );

  const getAllZoneBySiteQuery = useZoneySiteQuery(
    {
      siteId: queryparams.siteId,
    },
    { refetchOnMountOrArgChange: true }
  );


  const [tableData, setTableData] = useState([]);

  const dropdown = (row: any) => {
    return (
      <UIDropdown
        data-testid="delete"
        items={[
          {
            label: "View Audit Logs",
            key: "2",
            icon: <AuditOutlined />,
            onClick: () => navigate("/auditlogs", { state: row }),
          },
        ]}
        placement="bottom"
      >
        <EllipsisOutlined className="ellipses" />
      </UIDropdown>
    );
  };

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "",
      className: "activeProcessTable",
      key: "timestamp",
      sorter: true,
      ellipsis: true,
      width: "20%",
      render: (text: any, row: RowData) => (
        <div className="d-flex align-items-center">
          <UITooltip
            title={moment(row?.createdAt).format("DD MMM YYYY hh:mm A")}
            placement="top"
          >
            <div className="pl-10 tableData">
              {moment(row?.createdAt).format("DD MMM YYYY hh:mm A")}
            </div>
          </UITooltip>
        </div>
      ),
    },
    {
      title: "Event",
      dataIndex: "eventName",
      key: "eventName",
      className: "activeProcessTable",
      ellipsis: true,
      width: "10%",
      render: (text: string, row: RowData) => (
        <div className="d-flex align-items-center">
          <UITooltip title={row.processName} placement="top">
            <div className="pl-10 tableData slice">{text}</div>
          </UITooltip>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "message",
      key: "message",
      className: "activeProcessTable",
      width: "50%",
      ellipsis: true,
      render: (text: string, row: RowData) => (
        <div>
          <UITooltip title={row.deviceStatus} placement="top">
          <div className="tableData">{row?.eventName === 'Summary' ? "Process Summary:" : text}</div>
          <div className="tableData">
              <ul>
                {row?.metaData?.items?.length > 0 &&
                  row?.metaData?.items?.map((x: any) => (
                    <li key={x?.epc}>
                      {x?.epc} | {x?.tagId} - {x?.description}{" "}
                    </li>
                  ))}
              </ul>
            </div>
            {row?.eventName === 'Summary' &&
            <div className="tableData">
              Total Cycles: {row?.metaData?.totalCycles} | 
              Total Successfull Cycles: {row?.metaData?.totalSuccess} | 
              Total Unsuccessfull Cycles: {row?.metaData?.totalFailed}
            </div>}
            
          </UITooltip>
        </div>
      ),
    },
    {
      title: "Notification Status",
      dataIndex: "notificationStatus",
      width: "20%",
      className: "centerTable",
      render: (notified: any, row: any) => {
        return (
          <div className="notificationdiv">
            {row?.eventName === "Fail" || row?.eventName === "Pass" ? (
              <>
                <span>
                  <strong>Email:</strong>{" "}
                  {notified?.email ? "Sent" : row?.eventName === "Fail" ? "Error occurred in sending Email" : "Not Sent" }
                </span>
                {notified?.webhook != "notfound" ? (
                  <span>
                    <strong>Webhook:</strong>{" "}
                    {notified?.webhook
                      ? "Sent"
                      : "Error occurred in sending notification"}
                  </span>
                ) : (
                  ""
                )}{" "}
              </>
            ) : (
              "NA"
            )}
          </div>
        );
      },
    },
  ];

  const getAllSiteOptions = () => {
    if (getAllSiteByUserQuery?.data?.result) {
      var temp: any[] = [];
      temp = getAllSiteByUserQuery?.data?.result?.map((site: any) => {
        return {
          text: site?.name,
          value: site?.siteId,
        };
      });
      return temp;
    } else if (getAllSiteByUserQuery?.data) {
      var temp2: any[] = [];
      temp2 = getAllSiteByUserQuery?.data?.map((site: any) => {
        return {
          text: site?.name,
          value: site?.siteId,
        };
      });
      return temp2;
    }
  };

  const getAllZoneBySiteOptions = () => {
    if (getAllZoneBySiteQuery?.data?.result) {
      var temp: any[] = [];
      temp = getAllZoneBySiteQuery?.data?.result?.map((zone: any) => {
        return {
          text: zone?.name,
          value: zone?.zoneId,
        };
      });
      return temp;
    } else if (getAllZoneBySiteQuery?.data) {
      var temp2: any[] = [];
      temp2 = getAllZoneBySiteQuery?.data?.map((zone: any) => {
        return {
          text: zone?.name,
          value: zone?.zoneId,
        };
      });
      return temp2;
    }
  };

  interface DataType {
    name: {
      first: string;
      last: string;
    };
    description: string;
    lastUpdated: string;
    createdAt: string;
  }
  interface Params {
    pagination?: TablePaginationConfig;
    sorter?: SorterResult<any> | SorterResult<any>[];
    total?: number;
    sortField?: string;
    sortOrder?: string;
  }

  useEffect(() => {
    setPagination({
      ...pagination,
      locale: { items_per_page: "" },
      total,
    });
  }, [total]);

  const fetchData = (params: Params = {}) => {
    setLoading(true);
    // getActiveProcess?.refetch()
    setSort({
      sortBy: params.sortField,
      sortOrder:
        params.sortOrder === "ascend"
          ? "1"
          : params.sortOrder === "descend"
          ? "-1"
          : undefined,
    });
    setPagination({
      ...params.pagination,
      locale: { items_per_page: "" },
      total,
    });
  };


  const auditLogs = usePrevAuditlogsQuery(auditParams, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (auditLogs?.isSuccess && !auditLogs?.isLoading) {
      setTableData(auditLogs?.data?.result?.rows);
      setLoading(false);
      setPagination({...pagination, total: auditLogs?.data?.result?.count})
    }
  }, [auditLogs?.isSuccess, auditLogs?.isLoading]);

  const handleChange = (e: any, x: any) => {
    x && x?.length > 0
      ? setDate({
          to: moment(x[1])?.format("YYYY-MM-DD"),
          from: moment(x[0])?.format("YYYY-MM-DD"),
        })
      : setDate(x);
    setDateParams(e);
  };

  const handleSiteChange = (value: any) => {
    setQueryparams({ ...queryparams, siteId: value });
  };

  const handleZoneChange = (value: any) => {
    setQueryparams({ ...queryparams, zoneId: value });
  };

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<RowData>
  ) => {
    fetchData({
      sortField: sorter.field as string,
      sortOrder: sorter.order as string,
      pagination: newPagination,
      ...filters,
    });
  };

  console.log("PAGINATON, table data", pagination, tableData);

  return (
    <>
      <section className="title-section">
        <Card className="uicard detailed-top-card">
          <Row justify="space-between">
            <Col span={24}>
              <Row gutter={10}>
                <Col span={12} className="slice">
                  <div className="title titleTenant slice tenant-subheading">
                    Audit Logs History
                  </div>
                </Col>
                <Col span={4} className="colPaddingdetail">
                  <UISelectInput
                    placeholder="Select Site"
                    name="siteId"
                    rules={[
                      {
                        required: true,
                        message: "Please select Site",
                      },
                    ]}
                    optionValue={getAllSiteOptions()}
                    onChange={handleSiteChange}
                  />
                </Col>
                <Col span={4} className="colPaddingdetail">
                  <UISelectInput
                    placeholder="Select Zone"
                    name="zoneId"
                    rules={[
                      {
                        required: true,
                        message: "Please select Zone",
                      },
                    ]}
                    optionValue={getAllZoneBySiteOptions()}
                    onChange={handleZoneChange}
                  />
                </Col>
                <Col span={4} className="calendercsscol">
                  <div className="identityCalendar">
                    <UICalendar
                      params={dateParams}
                      setParams={setDateParams}
                      handleChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </section>
      <section className="listing-section">
        <Card className="uicard table uiinner-card">
          <div className="card-body">
            <UITable
              columns={columns}
              data={auditLogs?.data?.result?.rows}
              className="tableAudit"
              rowKey="tenant"
              pagination={pagination}
              loading={loading}
              handleTableChange={handleTableChange}
              scroll={{ x: 1000, y: 500 }}
            />
          </div>
        </Card>
      </section>
    </>
  );
};
