import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setUserData, logOut, authStateSelector } from '../features/auth/authSlice'
import { StatusCodes } from 'http-status-codes'
import { apiRoutes, baseURL } from '../../config/api'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setSessionExist } from '../features/app/appSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: baseURL,
    // credentials: 'include',
    prepareHeaders: (headers, { getState }:any) => {
        const token = getState().auth.token
        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args:any, api:any, extraOptions:any) => {
    let result:any = await baseQuery(args, api, extraOptions)
    return result
}

export const apiSlice = createApi({
    baseQuery:baseQueryWithReauth,
    endpoints: builder => ({}),
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 1,
})
