import { Tooltip } from "antd";
import "./style.css";

const UITooltip = (props: any) => {
  const { title, color, children, placement} = props;
  return (
    <>
          <Tooltip
            title={title}
            color={color}
            placement={placement}
          >
            {children}
          </Tooltip>
    </>
  );
};

export default UITooltip;
