import React from "react";
import { Card, Collapse } from "antd";
import "./style.css";
import { CompWrapper } from "../../common/customScrolbar";

const { Panel } = Collapse;

export const Help = () => {
  const faqs = [
    {
      question: "How do I execute a process?",
      answer:
        "To execute a process, navigate to the appropriate section of the application and select the desired process to run.",
    },
    {
      question: "What is displayed on the Audit Log screen?",
      answer:
        "The Audit Log screen displays a detailed history of all executed processes, including timestamps and user actions.",
    },
    {
      question: "What is the difference between Active and Inactive processes?",
      answer:
        "Active processes are currently running, while Inactive processes have completed execution or have not been started.",
    },
    {
      question:
        "Can I schedule a process to run automatically at specific intervals?",
      answer:
        "Yes, you can schedule processes to run at specified intervals using the scheduling feature in the application.",
    },
    {
      question:
        "What happens if the device associated with a process goes offline during execution?",
      answer:
        "If the device goes offline, the process may fail or pause until the connection is restored.",
    },
    {
      question:
        "Can I run multiple processes concurrently, and how does it impact performance?",
      answer:
        "Yes, you can run multiple processes concurrently, but be aware that it may impact performance based on system resources.",
    },
    {
      question:
        "What could cause a process to fail, and how can I diagnose the issue?",
      answer:
        "Processes can fail due to various reasons such as resource unavailability or configuration errors. Check the logs for diagnostic information.",
    },
    {
      question: "How can I troubleshoot if a process fails?",
      answer:
        "To troubleshoot a failed process, review the error messages in the logs, verify configurations, and ensure all resources are available.",
    },
  ];

  return (
    <section className="listing-section">
      <Card className="uicard table uiinner-card">
        <div className="card-body height">
          <h2 className="extra">About Us</h2>
          <h3 className="extra">
            Smart Cosmos empowers you to digitize your physical products and
            assets. The Smart Cosmos platform creates secure digital identities
            for each product instance — and provides the means to authenticate,
            track and trace — engaging customers, partners and employees through
            the value chain. For the first time, brands get the highest possible
            level of visibility and transparency for each individual product or
            asset from point of manufacture, through the entire supply chain,
            across all channels, point of sale and point of use. Smart Cosmos
            fundamentally changes how consumers connect with brands through the
            lifecycle of the product, as easy as a tap with a smart phone. This
            transforms existing physical products into engaging, in-the-moment
            digital experiences. Contact Details
          </h3>
          <h2 className="extra margin">Need Assistance?</h2>
          <h3 className="extra">
            For any inquiries or support, please contact us at our customer
            support email: <b>Customersupport@smartcosmos.com</b>.
          </h3>
          <h2 className="extra margin">Frequently Asked Questions (FAQs)</h2>

          <CompWrapper>
            <Collapse>
              {faqs.map((faq, index) => (
                <Panel header={faq.question} key={index}>
                  <p>{faq.answer}</p>
                </Panel>
              ))}
            </Collapse>
          </CompWrapper>
          
        </div>
      </Card>
    </section>
  );
};
