import { Modal, Row, Col } from "antd";
import { ArrowLeftOutlined, WarningFilled } from "@ant-design/icons";
import "./style.css";
import { Page } from "../../../routes/config";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UIbutton } from "../button";
import { removeCurrentStep, removeProcessState } from "../../../redux/features/app/appSlice";

export const ErrorModal = (props: any) => {
  const { openAlert, setOpenAlert, errorDetail, setErrorDetail, data } = props;
  const dispatch = useDispatch();

  const handleOk = () => {
    console.log("okay");
  };

  const navigate = useNavigate();
  const handleCancel = () => {
    if(window.location.pathname === Page.ACTIVITY_LOGS){
        navigate(Page.EXECUTE_PROCESS)
        setOpenAlert(false)
        setErrorDetail({})
        dispatch(removeProcessState({
          processId: data?.data?.processId,
          deviceId: data?.data?.deviceId
        }))
        dispatch(removeCurrentStep({
          processId: data?.data?.processId,
          deviceId: data?.data?.deviceId
        }))
    }else{
        setOpenAlert(false);
        setErrorDetail({})
        dispatch(removeProcessState({
          processId: data?.data?.processId,
          deviceId: data?.data?.deviceId
        }))
        dispatch(removeCurrentStep({
          processId: data?.data?.processId,
          deviceId: data?.data?.deviceId
        }))
    }  
  };
console.log(">>>>>>> context", errorDetail)
  return (
    <Modal
      visible={openAlert}
      footer={null}
      title={null}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      key="alertmodal"
      className="inclass"
    >
      <Row>
        <Col span={24} className="redCrossImg">
          <WarningFilled style={{ fontSize: "350%" }} className="warning" />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24} className="alertText errorAlert">
         {errorDetail?.message}
        </Col>
        <Col span={10} className="goback" onClick={handleCancel}>
        <ArrowLeftOutlined /><u>  Go to Execute Process</u>
          </Col>
      </Row>
    </Modal>
  );
};
