export const colors = [
  {
      backgroundColor:"#FFB7B54D",
      color:"#DC143C",
  },
  {
      backgroundColor:"#227F994D",
      color:"#227F99",
  },
  {
      backgroundColor:"#FF78414D",
      color:"#FF7841",
  },
  {
      backgroundColor:"#99B0E04D",
      color:"#0E3077",
  },
];

export const page = {
  current: 1,
  pageSize: 10,
  totalrows: 200,
};

export const GOOGLE_API_KEY = "AIzaSyC76a0Bs9KstBFD4zrjCFjAVW-WJglMtoM";

export const Regex = {
  PASSWORD:
    /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&,.#><|~{}():;"'/_=+-])[A-Za-z\d@$!%*?&,.#><|~{}():;"'/_=+-]{8,}$/,
  PASSWORD_VALID_LENGTH: /^\S{8,}$/,
  VALID_EMAIL: /^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$/,
  VALID_USERNAME: /^\S{6,}$/,
};

export const ZoneType = [
  { text: "Sold", value: "Sold" },
  { text: "Hold", value: "Hold" },
  { text: "Retired", value: "Retired"},
  { text: "Faulty", value: "Faulty"},
  { text: "Returns", value: "Returns"}
];

export const DeviceType = [
    {text:"Fixed", value:"fixed"},
    {text:"Handheld", value:"handHeld"}
]

export const DeviceModel = [
  {text:"Keonn", value:"keonn"},
  {text:"Mcon", value:"mcon"}
]

export const rolesOrder:any = {
  "Platform Super Admin": 1,
  "Platform Admin": 2,
  "Tenant Admin": 3,
  "Project Manager": 4,
  "Supervisor": 5,
  "Operator": 6,
  "Factory Tag Operator": 7,
  "API Operator": 8,
  "API Operator(Read Only)": 9
};

export enum ProcessName {
  CHECK_STOCK = "Check Reference Stock"
}