/* eslint react-hooks/exhaustive-deps: 0 */

import { Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";

import { Page } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { authStateSelector, logOut } from "../redux/features/auth/authSlice";
import { useEffect } from "react";

export const ProtectedRoute = ({ redirectPath = Page.LOGIN }: any) => {
  const {token} = useSelector(authStateSelector)
    let user = !!token ? true: false;
    // const dispatch = useDispatch();
  //   useEffect(() => {
  //       checkLoginSession();
  //   }, []);
  
  // function checkLoginSession(){
  //   const interval = setInterval(() => {
  //     if(token !== undefined && token !== null && token !== ""){
  //     let decoded:any = jwt_decode(token);
  //     let expireTimestamp = decoded?.exp;
  //     let currentTimestamp = new Date().getTime()/1000;
  //       let isExpired = expireTimestamp > currentTimestamp ? false : true;
  //       if(isExpired) {
  //         dispatch(logOut({}));
  //       }
        
  //     }
  //   }, 10000);
  //   return () => clearInterval(interval);
  
  // }
  
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
}
  
