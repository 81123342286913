import { Alert, Layout, message } from "antd";
import "./style.css";
import { UIImage } from "../image";
import { UIDropdown } from "../dropdown";
import {
  dropDownIcon,
  hammerBergIcon,
  logoutLightIcon,
} from "../../../assets/icons";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Page } from "../../../routes/config";
import { UIAvatar } from "../avatar";
import { scRoundLogo, headerTopLogo } from "../../../assets/images";
import {
  authStateSelector,
  logOut,
  selectCurrentUser,
} from "../../../redux/features/auth/authSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { UIbutton } from "../button";
import { StopEventAlert } from "./warningStop";
import { socket } from "../../../redux/services/webSocket";
import {
  resetAppData,
  selectCurrentAppState,
  setSessionExist,
} from "../../../redux/features/app/appSlice";
import { ErrorModal } from "../errorModal";
import { WebsocketKey } from "../../../config/enum";
import { resetFilter } from "../../../redux/features/filter/filterSlice";
import { resetTenant } from "../../../redux/features/tenant/tenantSlice";

const { Header } = Layout;

const SmartCosmosLogo = ({ collapsed }: any) => (
  <Link to={Page.ACTIVE_PROCESS}>
    <div className="sclogo">
      <div className="sphere">
        <UIImage src={scRoundLogo} text="small-logo" width={45} />
      </div>
      {!collapsed && (
        <div className="logotext">
          <UIImage src={headerTopLogo} text="text-logo" />
        </div>
      )}
    </div>
  </Link>
);

const UIHeader = (props: any) => {
  const user = useSelector(selectCurrentUser);
  const { isLoggedIn } = useSelector(authStateSelector);
  const { collapsed, setCollapsed } = props;
  const showSidebar = () => {
    if (window.location.pathname === Page.ACTIVITY_LOGS) {
      return false;
    } else {
      return true;
    }
  };
  const dispatch = useDispatch();
  const { sessionExist } = useSelector(selectCurrentAppState);
  const { state }:any = useLocation();

  const userDropDownOptions = [
    {
      label: "Logout",
      key: "1",
      onClick: () => {
        userLogout()
      },
      icon: <img src={logoutLightIcon} alt="icon" />,
    },
  ];

  const userLogout = () => {
    window.localStorage.clear();
    dispatch(logOut({}));
    dispatch(resetAppData({}))
    dispatch(resetFilter({}))
    dispatch(resetTenant({}))
    dispatch(setSessionExist(false));
  };

  const [stopAlert, setStopAlert] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [errorDetail, setErrorDetail] = useState();
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [runNodeError, setRunNodeError] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  socket.on("message", (data: any) => {
    setOpenAlert(true);
    setStopAlert(data);
  });

  socket.on(`${WebsocketKey.DATA_SYNC_CRS_ERR}-${state?.data?.deviceId}-${state?.data?.processId}`, (data: any) => {
    if(window.location.pathname === Page.ACTIVITY_LOGS && state?.data?.processId === data?.processId && state?.data?.deviceId === data?.deviceId){
      console.log("entry point in data sync")
    setErrorDetail(data);
    setOpenErrorAlert(true);
    }
    console.log(data, "message for alert event");
  });

  socket.on(`${WebsocketKey.ERROR_IN_RUNNODE}-${state?.data?.deviceId}-${state?.data?.processId}`, (data:any) => {
    if(window.location.pathname === Page.ACTIVITY_LOGS && state?.data?.processId === data?.processId && state?.data?.deviceId === data?.deviceId){
    setRunNodeError(data?.data)  
    setOpenModal(true)
    }
    console.log(data, ">>>>>>>>>>>>>>>>>>>erorrrrr")
  })

  return (
    <>
      <Header className="site-layout-background fixedHeader header">
        <div
          className={`logo ${collapsed ? "collapsedWidth" : "sidebarWidth"}`}
          style={{ textAlign: "center", transition: "0s" }}
        >
          <SmartCosmosLogo collapsed={collapsed} />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "calc(100% - 250px)",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {showSidebar() ? (
              <div
                className="hammerberg"
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
              >
                <UIImage src={hammerBergIcon} text="icon" />
              </div>
            ) : null}
          </div>
          <UIDropdown items={userDropDownOptions} placement="bottom">
            <div className="profileImageSection">
              <div className="profileImage">
                {user?.imageUrl ? (
                  <UIImage src={user?.imageUrl} />
                ) : (
                  <UIAvatar text={user?.name} type="username" />
                )}
              </div>
              <div className="info">
                <div className="name">{user?.name}</div>
                <div className="role">{user?.roleName}</div>
              </div>
              <div className="icon">
                <img
                  src={dropDownIcon}
                  className="header-logout"
                  alt="dropdown icon"
                />
              </div>
            </div>
          </UIDropdown>
        </div>
      </Header>
      {/* session expire alert */}
      {!sessionExist && (
        <div className="session_expired_alert">
          <div className="content">
            <div className="message">Session Expired! Need to Login</div>
            <div className="btn">
              <UIbutton type="info" size="md" onPress={userLogout}>
                Login
              </UIbutton>
            </div>
          </div>
        </div>
      )}
      <StopEventAlert
        processData={state}
        alertDetails={stopAlert}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
      />
      <ErrorModal
        errorDetail={errorDetail}
        setErrorDetail={setErrorDetail}
        openAlert={openErrorAlert}
        setOpenAlert={setOpenErrorAlert}
        data={state}
      />
       <ErrorModal
        errorDetail={runNodeError}
        setErrorDetail={setRunNodeError}
        openAlert={openModal}
        setOpenAlert={setOpenModal}
        data={state}
      />
    </>
  );
};

export default UIHeader;
