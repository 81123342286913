import { CiCircleOutlined, CloseCircleFilled } from "@ant-design/icons";
import { DatePicker, message, Row } from "antd";
import moment from "moment";
import { useState } from "react";
import { leftArrowIcon, rightArrowIcon } from "../../../assets/icons";
import { dateRanges
  
 } from "../../../config/enum";
import { UIsecondaryButton } from "../button";
import "./style.css";

export const UICalendar = (props: any) => {
  const { className, dropdownClassName, params, setParams, handleChange } =
    props;
  const { RangePicker } = DatePicker;

  const [range, setRange] = useState<any>();
  const [open, setOpen] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const onPrevClick = () => {
    switch (range) {
      case dateRanges.TODAY:
        return setParams([params[0]?.subtract(1, "day"), params[1]]);

      case dateRanges.YESTERDAY:
        return setParams([params[0]?.subtract(1, "day"), params[1]]);

      case dateRanges.THISWEEK:
        return setParams([
          params[0]?.subtract(1, "week"),
          params[1]?.subtract(1, "week"),
        ]);

      case dateRanges.LASTWEEK:
        return setParams([
          params[0]?.subtract(1, "week"),
          params[1]?.subtract(1, "week"),
        ]);

      case dateRanges.THISMONTH:
        return setParams([
          params[0]?.subtract(1, "month"),
          params[1]?.subtract(1, "month"),
        ]);

      case dateRanges.LASTMONTH:
        return setParams([
          params[0]?.subtract(1, "month"),
          params[1]?.subtract(1, "month"),
        ]);

      case dateRanges.LASTSIXMONTH:
        return setParams([
          params[0]?.subtract(6, "month"),
          params[1]?.subtract(6, "month"),
        ]);

      default:
        return setParams([params[0]?.subtract(1, "day"), params[1]]);
    }
  };
  const onNextClick = () => {
    switch (range) {
      case dateRanges.TODAY:
        return setParams([params[0], params[1].add(1, "day")]);

      case dateRanges.YESTERDAY:
        return setParams([params[0], params[1].add(1, "day")]);

      case dateRanges.THISWEEK:
        return setParams([params[0].add(1, "week"), params[1].add(1, "week")]);

      case dateRanges.LASTWEEK:
        return setParams([params[0].add(1, "week"), params[1].add(1, "week")]);

      case dateRanges.THISMONTH:
        return setParams([
          params[0].add(1, "month"),
          params[1].add(1, "month"),
        ]);

      case dateRanges.LASTMONTH:
        return setParams([
          params[0].add(1, "month"),
          params[1].add(1, "month"),
        ]);

      case dateRanges.LASTSIXMONTH:
        return setParams([
          params[0].add(6, "month"),
          params[1].add(6, "month"),
        ]);

      default:
        return setParams([params[0], params[1].add(1, "day")]);
    }
  };

  document.addEventListener("mousedown", (e) => {
    let weeklyOpt = document.elementFromPoint(e.clientX, e.clientY);
    let x = weeklyOpt?.textContent;
    if (
      x == dateRanges.LASTWEEK ||
      x == dateRanges.THISWEEK ||
      x == dateRanges.TODAY ||
      x == dateRanges.YESTERDAY ||
      x == dateRanges.THISMONTH ||
      x == dateRanges.LASTMONTH ||
      x == dateRanges.LASTSIXMONTH
    ) {
      setRange(x);
    }
  });

  const handleRange: any = {
    [dateRanges.TODAY]: [moment(), moment()],
    [dateRanges.YESTERDAY]: [moment()?.subtract(1, "day"),moment()?.subtract(1, "day")],
    [dateRanges.THISWEEK]: [moment().startOf("week"), moment().endOf("week")],
    [dateRanges.LASTWEEK]: [
      moment()?.subtract(1, "week").startOf("week"),
      moment()?.subtract(1, "week").endOf("week"),
    ],
    [dateRanges.THISMONTH]: [moment().startOf("month"), moment().endOf("month")],
    [dateRanges.LASTMONTH]: [
      moment()?.subtract(1, "month").startOf("month"),
      moment()?.subtract(1, "month").endOf("month"),
    ],
    [dateRanges.LASTSIXMONTH]: [
      moment()?.subtract(6, "month").startOf("month"),
      moment()?.subtract(1,"month").endOf("month"),
    ],
  };
  const Format = "MMM DD, YYYY";
  const handleCancel = () => {
    return setVisible(false);
  };

  const extraFooter = () => {
    return (
      <>
        <UIsecondaryButton
          className="calendarCancel"
          type="sm"
          onPress={handleCancel}
        >
          <span className="calendarTxtCncl">Cancel</span>
        </UIsecondaryButton>
      </>
    );
  };
  return (
    <>
      <Row>
        <div className="duration-filter" onClick={onPrevClick}>
          <img src={leftArrowIcon} className="navigationIcon" />
        </div>
        

<RangePicker 
 showTime
 className={className ? `calendarRange ${className}` : "calendarRange"}
 key={params?.toString()}
 format="MMM DD, YYYY"
 dropdownClassName={
  dropdownClassName
    ? `rangeDropDown ${dropdownClassName}`
    : "rangeDropDown"
}
renderExtraFooter={extraFooter}
ranges={handleRange}
defaultValue={params}
allowClear={false}
value={params}
open={isVisible}
onChange={(e: any, x) => handleChange(e, x)}
separator={<span style={{ fontSize: "20px" }}>-</span>}
onOk={() => {
  setRange(null);
}}
onCalendarChange={(obj: any) => {
  if (!!obj[0] && !!obj[1]) {
    setVisible(false);
  }
}}
onClick={() => setVisible(true)}
/>
      
       {params?.length ? (
        <div className="closeIconBtn" onClick={
        ()=>{
          handleChange([]);
          setParams([]);
          setVisible(false);
        }
       }><CloseCircleFilled style={{color: "rgba(0,0,0,.25)"}}/></div>
       ): ""}

        <div className="duration-filter" onClick={onNextClick}>
          <img src={rightArrowIcon} className="navigationIcon" />
        </div>
      </Row>
    </>
  );
};
